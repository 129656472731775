.block-team .inner {
  padding-top: 6rem;
  padding-bottom: 6rem;

  .team {
    display: flex;
    justify-content: space-between;
  }
}

.team-member {
  padding: 1rem;
  text-align: center;
  width: 20%;
  box-sizing: border-box;

  &__picture {
    border-radius: 300px;
    overflow: hidden;
    margin-bottom: 0.6rem;

    img {
      max-width: 100%;
      height: auto;
      display: block;
    }
  }

  &__name {
    margin: 0;
    font-size: 1.6em;
    font-weight: normal;
    margin-bottom: 0.8rem;
  }

  &__role {
    margin: 0;
    font-size: 0.95em;
    font-weight: 600;
    font-style: italic;
  }

  &__description {
    font-size: 0.8em;
    opacity: 0.76;

    p {
      margin-bottom: 0;
    }
  }
}
