.block-contact-us {
  display: flex;
  flex-direction: row-reverse;

  .hidden {
    display: none;
  }

  .contact-us__form {
    width: 58%;
    box-sizing: border-box;
    padding: 3.2rem 6.4rem;

    h2 {
      margin-bottom: 0;
      font-size: 4.6em;
    }

    p {
      font-size: 1.5em;
    }

    form {
      margin-top: 1.6rem;
      max-width: 64rem;
    }
  }

  .contact-us__illustration {
    width: 42%;
    background-image: url("/img/contact-illustration.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;

    &__contact-card {
      background: var(--color-background);
      line-height: 1;
      // border-radius: 8px;
      border-radius: 0 8px 8px 0;
      position: absolute;
      left: 0;
      bottom: 6rem;
      padding: 1.6rem;
      padding-right: 2.4rem;
      padding-left: 10rem;

      h3 {
        font-size: 1.4em;
        font-family: var(--font-body);
        font-weight: 600;
        img {
          vertical-align: bottom;
          height: 0.8em;
        }
      }

      p {
        margin: 0;
        font-size: 1.2em;
      }
    }
  }
}

.form-input {
  margin-bottom: 0.8em;

  label {
    display: block;
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold;
    margin-bottom: 0.4rem;
  }

  input[type="text"],
  input[type="email"],
  textarea {
    display: block;
    width: 100%;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid var(--color-border);
    border-radius: 2px;
    padding: 0.6rem 0.8rem;

    &:focus,
    &:focus-visible {
      box-shadow: none;
      outline: transparent;
      border-color: var(--color-border-dark);
    }
  }

  textarea {
    resize: vertical;
    min-height: 4em;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
