.block-footer {
  padding: 6rem 2rem 4rem 2rem;
  text-align: center;

  img {
    max-width: 26rem;
    display: inline-block;
    margin-bottom: 1.6em;
  }
}
