:root {
  font-family: var(--font-body);
  color: var(--color-text);
  font-size: 10px;
}

:root > * {
  font-size: var(--font-size);
}

h1,
h2,
h3,
h4 {
  font-family: var(--font-title);
  margin-top: 0;
  line-height: 1;
  margin-bottom: 0.4em;
}

h1,
.h1 {
  font-size: var(--font-size-H1);
}
h2,
.h2 {
  font-size: var(--font-size-H2);
}
h3,
.h3 {
  font-size: var(--font-size-H3);
}
h4,
.h4 {
  font-size: var(--font-size-H4);
}

a {
  color: var(--color-accent);
  font-weight: 600;
  text-decoration-skip: edges;
  text-decoration-color: var(--color-accent-faded);
}

li {
  list-style-type: none;
  margin-left: 0.4rem;
  margin-bottom: 0.4rem;
  &:before {
    content: " - ";
  }
}
