// tablet
@media (max-width: 960px) {
  :root {
    --side-spacing: 3.2rem !important;
    font-size: 9px !important;
  }

  .block-about-us {
    .inner {
      padding: 2rem var(--side-spacing);
    }
  }

  .block-services {
    .illustration {
      display: none;
    }

    .service-item {
      margin-bottom: 12rem;
      gap: 1.6rem;

      &:nth-child(1) .service-item__main {
        margin-top: 0;
      }

      &__main {
        width: 56%;
      }

      &__picture {
        margin-top: 6rem;
      }

      &:last-of-type {
        margin-bottom: 6rem;
      }
    }
  }

  .block-gallery {
    padding: 0;

    .inner {
      border-radius: 0;
      box-shadow: none;
    }
  }

  .block-contact-us {
    display: block;
    margin: 0 auto;

    .contact-us__form {
      width: auto;
    }

    .contact-us__illustration {
      display: none;
    }
  }

  .page-gallery .gallery-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

// mobiles
@media (max-width: 620px) {
  .header .inner {
    justify-content: center;
    padding: 1.2rem;
  }
  .header__nav {
    display: none;
  }
  .header__logo {
    max-width: 30%;
    margin-right: 0;
  }

  .block-leading-video {
    min-height: 22rem;

    .content {
      margin-top: 8rem;
    }
  }

  .leading-video__title {
    font-size: 10vw;
  }

  .block-leading-video {
    max-height: 40rem;
  }

  .block-about-us {
    font-size: 0.7em;
    margin-top: 0;
    padding: 0;
    .inner {
      border-radius: 0;
      box-shadow: none;
      padding: 2.4rem var(--side-spacing);
    }
  }

  .block-services {
    h2 {
      margin-bottom: 6rem;
      font-size: 2.8em;
    }
    .service-item {
      flex-direction: column-reverse !important;
      margin-bottom: 6rem;

      &__main {
        width: auto;
        text-align: left !important;
      }

      &__picture {
        width: auto;
        position: static;
        margin-top: 0;

        &::after {
          display: none;
        }
      }
    }
  }

  .block-gallery {
    .gallery {
      flex-wrap: wrap;
    }
    .gallery-item {
      flex-basis: 40%;
      flex-grow: 1;
    }
  }

  .block-partners .partners {
    flex-wrap: wrap;
    justify-content: space-evenly;

    .partner {
      width: 30%;
    }
  }

  .block-team .inner .team {
    flex-wrap: wrap;
    justify-content: center;
    display: block;

    .team-member {
      width: auto;
      display: flex;
      padding-left: 0;
      padding-right: 0;
      // gap: 2rem;
      text-align: left;
      align-items: center;
      &:nth-child(even) {
        flex-direction: row-reverse;
        text-align: right;
      }

      &__picture {
        width: 42%;
        height: 42%;
        flex-grow: 0;
        flex-shrink: 0;
        font-style: 0;
        margin: 0 1.6rem;
      }
      &__content {
        flex-grow: 1;
      }
      margin-bottom: -1.6rem;
    }
  }
  .block-contact-us {
    font-size: 0.85em;
    .contact-us__form {
      padding: 3.2rem var(--side-spacing);
      h2 {
        font-size: 4em;
      }
    }
  }

  .page-about-us {
    .illustration {
      img {
        max-width: none !important;
        width: 240%;
        margin-left: -70%;
      }
    }
    .background-picture img {
      // display: none !important;
      // width: 140%;
      // margin-left: -20%;
    }
  }

  .page-gallery .gallery-container {
    grid-template-columns: 1fr 1fr;
  }
}

// large screens
@media (min-width: 1439px) {
  .block-services .illustration {
    top: 48rem;
  }
}

@media (min-width: 2000px) {
  :root {
    --font-size: 2em !important;
    font-size: 12px !important;
  }
  .block-services .illustration {
    top: 56rem;
  }
}
