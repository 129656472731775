.block-gallery {
  padding: 0 var(--side-spacing);

  .inner {
    margin: 0 auto;
    padding: 4rem;
    border-radius: 8px;
    // text-align: center;
    box-shadow: (0px 20px 25px rgba(0, 0, 0, 0.1)),
      (0px 10px 10px rgba(0, 0, 0, 0.04));

    .btn {
      color: var(--color-revert-background);
    }
  }
}

.gallery {
  display: flex;
  margin: 2rem 0;
  gap: 2rem;
}

.gallery-item {
  width: 25%;
  cursor: pointer;
  position: relative;
  transition: bottom var(--transition-short) ease-in-out;
  bottom: 0;

  // &:hover {
  //   bottom: 1.2rem;
  // }

  &__image {
    overflow: hidden;
    border-radius: 2px;
    display: block;
    // background: var(--color-accent-light);
  }

  p {
    color: var(--color-text);
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
    // mix-blend-mode: luminosity;
  }
}

.page-gallery {
  .gallery-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0.8rem;
  }
  .gallery-item {
    width: auto;
  }
}
