:root {
  // ================
  // ==== COLORS ====
  // ================
  --color-text: #393939;
  --color-text-accent: #262626;
  --color-background: #f8f8f8;
  --color-revert-background: #1e1e1e;
  --color-revert-text: #a8a8a8;
  --color-border: #c6c6c6;
  --color-border-dark: #a8a8a8;
  --color-accent: #59ba4f;
  --color-accent-light: #81d378;
  --color-accent-dark: #396e33;
  --color-white: #fff;

  // ================
  // ==== FONTS =====
  // ================
  --font-body: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-title: "Rozha One", serif;
  // base font size
  --font-size: 1.6em;
  // ratio based bigger/smaller sizes
  --font-size-big: calc(var(--font-size) * 1.15);
  --font-size-bigger: calc(var(--font-size-big) * 1.15);
  --font-size-biggest: calc(var(--font-size-bigger) * 1.15);
  --font-size-small: calc(var(--font-size) / 1.15);
  --font-size-smaller: calc(var(--font-size-small) / 1.15);
  --font-size-smallest: calc(var(--font-size-smaller) / 1.15);
  // titles sizes
  --font-size-H1: calc(var(--font-size) * 2.125);
  --font-size-H2: calc(var(--font-size) * 1.625);
  --font-size-H3: calc(var(--font-size) * 1.125);
  --font-size-H4: var(--font-size);

  // ================
  // ==== SIZES =====
  // ================

  // ================
  // ==== OTHER =====
  // ================
  --box-shadow: 0px 0.5rem 1.5rem rgba(0, 0, 0, 0.12),
    0px 0.15rem 0.5rem rgba(48, 48, 52, 0.06);
  --transition-short: 180ms;
  --transition-medium: 320ms;
  --transition-long: 500ms;
}
