.block-leading-video {
  position: relative;
  overflow: hidden;
  max-height: 66rem;

  .background-video {
    position: relative;

    video {
      width: 110%;
      margin-left: -5%;
      height: auto;
      display: block;
    }

    .background-video__gradient {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(
        93.54deg,
        #000000 3.84%,
        rgba(0, 0, 0, 0) 56.35%
      );
    }
  }

  .content {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    margin-top: 14rem;
  }
}

.leading-video__title {
  font-size: 7.8vw;
  line-height: 1;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.1),
    1px 1px 4px #000000;

  strong {
    color: var(--color-accent);
  }

  &::before {
    content: " ";
    height: 0.1em;
    width: 30%;
    display: block;
    background-color: var(--color-background);
    margin-bottom: 0.2em;
  }
}

@media screen and (min-width: 1200px) {
  .leading-video__title {
    font-size: 11rem;
  }
}
