.page-about-us {
  .text-block {
    position: relative;
    padding: 6rem 0;
    overflow: hidden;

    &--dark {
      background: var(--color-revert-background);
      color: var(--color-revert-text);
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: var(--color-background);
      }
    }

    .inner {
      position: relative;
      z-index: 1;
    }
  }

  .page__heading {
    position: relative;
    max-height: 36rem;
    overflow: hidden;

    h1 {
      color: var(--color-background);

      &::before {
        content: " ";
        height: 0.1em;
        width: 30%;
        display: block;
        background-color: var(--color-background);
        margin-bottom: 0.2em;
      }
    }

    .content {
      position: absolute;
      bottom: 4rem;
      left: 0;
      right: 0;
    }
  }

  .illustration {
    overflow: hidden;

    img {
      max-width: 100%;
      height: auto;
      display: block;
    }
  }

  .background-picture {
    position: absolute;
    // top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: grid;
    place-content: center;
    overflow: hidden;
    background: #000;

    picture,
    img {
      max-width: 110%;
      opacity: 0.3;
    }
  }

  .text-block--pic1 {
    padding: 8rem 0;
  }
  .text-block--pic2 {
    padding: 12rem 0;
  }
}
