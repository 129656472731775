.block-services {
  position: relative;
  justify-content: flex-end;
  text-align: center;
  margin-top: 4rem;

  .inner {
    max-width: 1600px;
    overflow: hidden;
    position: relative;
  }

  h2 {
    font-size: 8.4rem;
    display: inline-block;
    margin-bottom: 10rem;
    line-height: 1.1;
    text-align: center;

    &::after {
      content: "";
      display: block;
      height: 0.1em;
      width: 45%;
      background: var(--color-text-accent);
    }
  }

  .service-item {
    text-align: left;
    display: flex;
    gap: 3rem;
    margin-bottom: 24rem;
    justify-content: flex-end;
    align-items: flex-start;

    img {
      width: 100%;
      height: auto;
    }

    ul {
      padding: 0;
    }

    &__picture {
      width: 37%;
      background-color: var(--color-accent);
      position: relative;

      img {
        mix-blend-mode: luminosity;
        display: block;
        position: relative;
        z-index: 1;
      }

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: var(--color-accent);
        opacity: 0.7;
      }
    }

    &__main {
      width: 40%;
      position: relative;
      z-index: 1;
      font-size: 1.3em;
    }

    &:nth-child(odd) {
      flex-direction: row-reverse;
    }

    &:nth-child(even) {
      text-align: right;
    }

    &:nth-child(1) {
      .service-item__main {
        margin-top: -2em;
      }

      .service-item__picture {
        right: 5%;
        &::after {
          bottom: 12%;
          left: 12%;
        }
      }
    }

    &:nth-child(2) {
      .service-item__picture {
        left: 8%;
        &::after {
          top: 20%;
          right: 10%;
        }
      }
    }

    &:nth-child(3) {
      .service-item__picture {
        &::after {
          top: 20%;
          right: 15%;
        }
      }
    }
  }

  .illustration {
    overflow: hidden;
    position: absolute;
    top: 36vw;
    bottom: 4vw;
    left: var(--side-spacing);
    right: var(--side-spacing);
    user-select: none;
    pointer-events: none;

    img {
      width: 100%;
      height: 100%;
    }

    .icon-1,
    .icon-2,
    .icon-3,
    .path {
      position: absolute;
    }

    .icon-1 {
      top: 0;
      right: 2.5%;
      width: 10%;
    }

    .icon-2 {
      top: 21.2%;
      left: 0;
      width: 7.5%;
    }

    .icon-3 {
      bottom: 0;
      right: 0;
      width: 13%;
    }

    .path {
      top: 11.8%;
      left: 0;
      right: 0;
      bottom: 17.5%;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
