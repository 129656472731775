.header .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
}

.header__logo {
  max-width: 15%;
  margin-right: 1em;

  .header__logo--white {
    display: none;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.header__nav {
  font-size: 1.8rem;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    transition: all ease-in-out var(--transition-short);

    &:before {
      content: "";
    }

    svg {
      display: inline-block;
      margin-right: 0.6rem;
      height: 0.8em;
    }
    path {
      transition: all ease-in-out var(--transition-short);
      fill: var(--color-text);
    }

    &:hover,
    &:hover path {
      color: var(--color-accent);
      fill: var(--color-accent);
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .menu-btn {
    color: var(--color-text);
    background: var(--color-background);
    border-radius: 4px;
    padding: 0.4rem 1.2rem;
    margin-left: 0.4rem;
    transition: all ease-in-out var(--transition-short);
    // vertical-align: middle;

    &:hover {
      background: var(--color-accent-dark);
      color: var(--color-background);
    }
  }
}

// This version is used only on index
.page-index {
  .header {
    background: rgba(0, 0, 0, 0.6);
    color: var(--color-background);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;

    &__logo--default {
      display: none;
    }

    &__logo--white {
      display: block;
    }
  }
}

.page-about-us {
  li:first-child {
    margin-right: 1.2rem;
  }
  .menu-btn {
    color: var(--color-background);
    background: var(--color-accent);

    &:hover {
      background: var(--color-accent-dark);
    }
  }
}
