.block-partners {
  padding: 4.8rem 0;

  .partners {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.8rem;
    margin-top: 3.2rem;
  }

  .partner {
    opacity: 0.7;
    transition: opacity ease-in-out var(--transition-medium);

    &:hover {
      opacity: 0.85;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
