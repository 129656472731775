.block-about-us {
  background-color: transparent;
  position: relative;
  top: 0px;
  padding: 0 var(--side-spacing);
  margin-bottom: 2em;
  margin-top: -16rem;

  h2 {
    font-size: 4em;
    margin-bottom: 0;
  }

  .mask {
    background: var(--color-background);
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 50%;
  }

  .inner {
    position: relative;

    z-index: 1;
    padding: 4rem var(--side-spacing);
    background: linear-gradient(
      100.51deg,
      var(--color-accent) 11.51%,
      var(--color-accent-dark) 83.99%
    );
    border-radius: 8px;
    color: #dcf1da;
    // font-weight: bold;
    box-shadow: (0px 20px 25px rgba(0, 0, 0, 0.1)),
      (0px 10px 10px rgba(0, 0, 0, 0.04));

    h2 {
      color: var(--color-white);
    }
  }

  &__content {
    font-size: 1.8em;
    margin-bottom: 0.8em;
  }
}
