.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 2;
  display: none;

  &.is-open {
    display: grid;
  }

  .container {
    background: var(--color-background);
    padding: 1.6rem;
    padding-bottom: 0;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.04),
      0px 20px 25px 0px rgba(0, 0, 0, 0.1);
    min-width: 40rem;
    min-height: 30rem;
    max-width: 68vw;
    max-height: 85vh;
    overflow: auto;
    border-radius: 4px;

    img {
      max-width: 100%;
      height: auto;
      // max-height: 100%;
      object-fit: scale-down;
      margin-bottom: 1.6rem;
    }

    p {
      text-align: center;
      font-size: 1.8em;
      margin-bottom: 0.8rem;
    }
  }
}
