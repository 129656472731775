@import "variables.scss";
@import "default.scss";

@import "header.scss";
@import "blocks/leading-video.scss";
@import "blocks/about-us.scss";
@import "blocks/services.scss";
@import "blocks/gallery.scss";
@import "blocks/team.scss";
@import "blocks/partners.scss";
@import "blocks/contact-us.scss";
@import "blocks/footer.scss";

@import "about.scss";
@import "lightbox.scss";
@import "responsiveness.scss";

:root {
  --side-spacing: 6rem;
  scroll-behavior: smooth;

  ::selection {
    color: var(--color-background);
    background: rgba(57, 110, 51, 0.9);
  }
}

body {
  background: var(--color-background);
  color: var(--color-text);
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--color-text-accent);
  font-weight: normal;
}

.is-revert {
  background: var(--color-revert-background);
  color: var(--color-revert-text);
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: var(--color-background);
  }
}

p {
  margin-top: 0;
}

.inner {
  max-width: 114rem;
  padding-left: var(--side-spacing);
  padding-right: var(--side-spacing);
  margin-left: auto;
  margin-right: auto;
}

.btn {
  display: inline-block;
  font-family: var(--font-body);
  font-size: 0.9em;
  padding: 1.2rem 2.4rem;
  background: linear-gradient(
    100.51deg,
    var(--color-accent) 11.51%,
    var(--color-accent-dark) 83.99%
  );
  border: none;
  border-radius: 4px;
  line-height: 1;
  text-transform: uppercase;
  color: var(--color-white);
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
  background-size: 200% 100%;
  transition: background ease-in-out var(--transition-short);
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 1px 2px 0px rgba(0, 0, 0, 0.06);

  &:hover {
    background-position: -50px;
  }
  &:active {
    background-position: -100px;
  }

  &--primary {
    font-size: calc(var(--font-body) * 1.15);
  }

  &--revert {
    background: var(--color-white);
    color: var(--color-accent);

    &:hover {
      opacity: 0.76;
    }

    &:active {
      opacity: 0.6;
    }
  }
}
